import React, { FC } from 'react';
import { Link } from 'gatsby';

import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { parseBoolean } from 'utils/parseHelpers';
import { ICardRelatedArticleProps } from './model';
import './CardRelatedArticle.scss';

const CardRelatedArticle: FC<ICardRelatedArticleProps> = ({
  articleData: { shortTitle, shortText, link = '/', alt, text, label, title, tags = [], image },
}) => {
  const category = tags?.find((tag) => parseBoolean(tag.articleCategory));
  const cardText = shortText || text;

  return (
    <div className="card-related-article">
      <Link to={link} className="card-related-article__link">
        {label ? <span className="card-related-article__label">{label}</span> : null}
        {image?.fallbackUrl ? (
          <GatsbyImage className="card-related-article__img" fluid={image} alt={alt} />
        ) : null}
        <div className="card-related-article__body">
          {category?.link?.[0].name ? (
            <p className="card-related-article__category">{category.link[0].name}</p>
          ) : null}
          {title || shortTitle ? (
            <DangerouslySetInnerHtml
              className="card-related-article__title"
              element="h2"
              html={shortTitle || title}
            />
          ) : null}
          {text || shortText ? (
            <DangerouslySetInnerHtml
              className="card-related-article__text"
              html={cardText.replace(/<\/?a[^>]*>/g, '')}
            />
          ) : null}
        </div>
      </Link>
    </div>
  );
};
export default CardRelatedArticle;
